import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import './App.css';

function App() {
  const [files, setFiles] = useState([]);
  const [brightness, setBrightness] = useState(100);
  const [imageWidth, setImageWidth] = useState(1920);
  const [imageHeight, setImageHeight] = useState(1080);
  const [previewImages, setPreviewImages] = useState([]);
  const [imagesReady, setImagesReady] = useState(false); 
  const [isLoading, setIsLoading] = useState(false); // Estado de carga

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setImagesReady(false); 
    setIsLoading(false); 
  };

  const handleBrightnessChange = (event) => {
    setBrightness(event.target.value);
  };

  const handleImageWidthChange = (event) => {
    setImageWidth(Number(event.target.value));
  };

  const handleImageHeightChange = (event) => {
    setImageHeight(Number(event.target.value));
  };

  const adjustBrightness = (img, brightness) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width;
    canvas.height = img.height;

    ctx.drawImage(img, 0, 0);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      data[i] = data[i] * (brightness / 100);     
      data[i + 1] = data[i + 1] * (brightness / 100); 
      data[i + 2] = data[i + 2] * (brightness / 100); 
    }

    ctx.putImageData(imageData, 0, 0);

    return canvas;
  };

  const processAndPreviewImages = async () => {
    setIsLoading(true); // Inicia la carga
    const previews = [];
    const verticalImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const img = new Image();
      img.src = URL.createObjectURL(file);

      await new Promise((resolve) => {
        img.onload = () => {
          const canvas = adjustBrightness(img, brightness);
          const ctx = canvas.getContext('2d');

          if (img.height > img.width) {
            verticalImages.push(canvas);
          } else {
            const resizedCanvas = document.createElement('canvas');
            resizedCanvas.width = imageWidth;
            resizedCanvas.height = imageHeight;
            const resizedCtx = resizedCanvas.getContext('2d');

            resizedCtx.drawImage(canvas, 0, 0, imageWidth, imageHeight);

            const dataURL = resizedCanvas.toDataURL('image/png');
            previews.push(dataURL);
          }

          resolve();
        };
      });
    }

    for (let i = 0; i < verticalImages.length; i += 2) {
      const canvas1 = verticalImages[i];
      const canvas2 = verticalImages[i + 1];

      const combinedCanvas = document.createElement('canvas');
      combinedCanvas.width = imageWidth;
      combinedCanvas.height = imageHeight;
      const combinedCtx = combinedCanvas.getContext('2d');

      const halfWidth = imageWidth / 2;

      combinedCtx.drawImage(canvas1, 0, 0, halfWidth, imageHeight);
      if (canvas2) {
        combinedCtx.drawImage(canvas2, halfWidth, 0, halfWidth, imageHeight);
      }

      const combinedDataURL = combinedCanvas.toDataURL('image/png');
      previews.push(combinedDataURL);
    }

    setPreviewImages(previews);
    setImagesReady(true); // Las imágenes están listas
    setIsLoading(false); // Finaliza la carga
  };

  const downloadImagesAsZip = async () => {
    const zip = new JSZip();

    previewImages.forEach((dataURL, index) => {
      const base64Data = dataURL.split(',')[1];
      zip.file(`image_${index + 1}.png`, base64Data, { base64: true });
    });

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'images.zip');
    });
  };

  useEffect(() => {
    if (files.length > 0) {
      processAndPreviewImages();
    }
  }, [files, brightness, imageWidth, imageHeight]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Modificador de Resolución de Imágenes</h1>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleFileChange}
        />
        <div>
          <label>Brillo: </label>
          <input
            type="range"
            min="0"
            max="200"
            value={brightness}
            onChange={handleBrightnessChange}
          />
          <span>{brightness}%</span>
        </div>
        <div>
          <label>Ancho de Imagen: </label>
          <input
            type="number"
            min="50"
            value={imageWidth}
            onChange={handleImageWidthChange}
          />
          <label>Alto de Imagen: </label>
          <input
            type="number"
            min="50"
            value={imageHeight}
            onChange={handleImageHeightChange}
          />
        </div>
        {isLoading && (
          <div className="loading-spinner">
            <div className="spinner"></div>
            Procesando imágenes...
          </div>
        )}
        <div 
          className={`button ${imagesReady ? '' : 'disabled'}`} 
          onClick={imagesReady ? downloadImagesAsZip : null}
          style={{cursor: imagesReady ? 'pointer' : 'not-allowed'}}
        >
          Descargar Imágenes en ZIP
        </div>
        <div className="preview-container">
          <h2>Vista Previa de Imágenes Redimensionadas</h2>
          <div className="preview-grid">
            {previewImages.map((src, index) => (
              <img key={index} src={src} alt={`Preview ${index + 1}`} className="preview-image" />
            ))}
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
